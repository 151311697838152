@import "@/variable.scss";

.ascii-tree {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;
  overflow-y: auto;
}

.ascii-tree pre {
  padding: 16px;
  overflow: auto;
  line-height: 1.45;
  color: #3a3a3a;
  background-color: #f5f6fa;
  border-radius: 3px;
  width: 100%;
}

.ascii-tree pre code {
  width: 100%;
  font-family: "Fira Mono", "Andale Mono", "Consolas", monospace;
}

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem;
}

.toolbar .tool {
  background: #FFF;
  border: 1px solid #0e88eb;
  color: #0e88eb;
  padding: .5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}