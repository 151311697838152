@import url(https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{display:flex;flex-flow:column nowrap;height:100vh;justify-content:center;align-items:center}.header{position:fixed;top:0;left:0;right:0;display:flex;flex-flow:row nowrap;justify-content:flex-start;align-items:center;height:60px;padding:0 32px;font-size:2rem;font-weight:bolder;color:#3a3a3a;font-family:'Liu Jian Mao Cao', cursive}.header img{height:40px}.button{border:none;color:#eff4ff;background:#3a78f2;padding:0.5rem 1rem;font-size:1.25rem;font-weight:bolder;border-radius:8px;text-align:left;box-shadow:0 0 2px 0 #3a78f2;outline:0;cursor:pointer;transition:box-shadow .3s}.button svg{fill:currentColor;margin:0 0.5rem}.button:hover{box-shadow:2px 2px 8px 0 #3a78f2}

.dropzone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 1140px;
  height: 600px;
  border: 4px solid #ddd;
  border-radius: 4px;
  color: #999;
  outline: dashed #ddd 4px;
  outline-offset: 4px;
}

.dropzone.active {
  background: #f5f7fa;
}
.main{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:flex-start;width:100%;height:100%}.tree-wrapper{flex-basis:600px;padding:20px 10px;height:100%;overflow-y:auto;background:#f5f6fa}.back-button{padding:1rem;font-size:2rem}.matcher-wrapper{display:flex;flex-flow:column nowrap;flex-grow:1;padding:1rem}.tree-wrapper,.matcher-wrapper{height:100%}

.node{position:relative}.node-path{display:flex;flex-flow:row nowrap;justify-content:flex-start;align-items:center;padding:0 2.5rem;height:2rem;line-height:2rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:#7d849d}.node-path .node-path_value{transition:all .3s}.node-path_icon--file,.node-path_icon--dir{fill:#a5adc6}.node-path_value{padding:0 0.3rem}.node .node{margin-left:2rem}.node::before{content:"";display:block;position:absolute;left:-2rem;top:-1rem;width:4rem;height:2rem;border-bottom:1px solid #ccc}.node-path+.node::before{border-left:1px solid #ccc}.node .node::after{content:"";display:block;position:absolute;border-left:1px solid #ccc;top:1rem;bottom:-1rem;left:-2rem}.node .node:last-child:after{display:none}.node.active .node-path{color:#3a78f2}.node.active .node-path_icon--file,.node.active .node-path_icon--dir{fill:#3a78f2}.node.active::before{border-color:#3a78f2}.node.sibling-active::before{border-left-color:#3a78f2}.node.sibling-active::after{border-color:#3a78f2}

.matcher{display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;width:100%}.matcher-form{margin-top:100px;display:flex;flex-flow:row nowrap;width:100%}.matcher-form .matcher-form_input{vertical-align:middle;border:1px solid #aaa;padding:1rem 2rem;font-size:1.5rem;border-radius:4px;flex-grow:1;outline:none;transition:all .3s}.matcher-form .matcher-form_input:focus{border-color:#3a78f2}

.ascii-tree{display:flex;flex-flow:column nowrap;justify-content:flex-start;align-items:flex-start;width:100%;margin-top:32px;overflow-y:auto}.ascii-tree pre{padding:16px;overflow:auto;line-height:1.45;color:#3a3a3a;background-color:#f5f6fa;border-radius:3px;width:100%}.ascii-tree pre code{width:100%;font-family:"Fira Mono", "Andale Mono", "Consolas", monospace}.toolbar{display:flex;flex-flow:row nowrap;justify-content:flex-end;align-items:center;width:100%;padding:.5rem 1rem}.toolbar .tool{background:#FFF;border:1px solid #0e88eb;color:#0e88eb;padding:.5rem 1rem;border-radius:4px;cursor:pointer}

