@import "@/variable.scss";
@import url('https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao&display=swap');

.App {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 0 32px;
  font-size: 2rem;
  font-weight: bolder;
  color: #3a3a3a;
  font-family: 'Liu Jian Mao Cao', cursive;

  img {
    height: 40px;
  }
}

.button {
  border: none;
  color: #eff4ff;
  background: map-get($lightTheme, "primary");
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: bolder;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 0 2px 0 map-get($lightTheme, "primary");
  outline: 0;
  cursor: pointer;
  transition: box-shadow .3s;

  svg {
    fill: currentColor;
    margin: 0 0.5rem;
  }

  &:hover {
    box-shadow: 2px 2px 8px 0 #3a78f2;
  }
}