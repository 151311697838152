.main {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.tree-wrapper {
  flex-basis: 600px;
  padding: 20px 10px;
  height: 100%;
  overflow-y: auto;
  background: #f5f6fa;
}

.back-button {
  padding: 1rem;
  font-size: 2rem;
}

.matcher-wrapper {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  padding: 1rem;
}

.tree-wrapper, .matcher-wrapper {
  height: 100%;
}