.node {
  position: relative;
}

.node-path {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2.5rem;
  height: 2rem;
  line-height: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #7d849d;

  .node-path_value {
    transition: all .3s;
  }
}

.node-path_icon--file, .node-path_icon--dir {
  fill: #a5adc6;
}

.node-path_value {
  padding: 0 0.3rem;
}

.node .node {
  margin-left: 2rem;
}

.node::before {
  content: "";
  display: block;
  position: absolute;
  left: -2rem;
  top: -1rem;
  width: 4rem;
  height: 2rem;
  border-bottom: 1px solid #ccc;
}

.node-path+.node::before {
  border-left: 1px solid #ccc;
}

.node .node::after {
  content: "";
  display: block;
  position: absolute;
  border-left: 1px solid #ccc;
  top: 1rem;
  bottom: -1rem;
  left: -2rem;
}

.node .node:last-child:after {
  display: none;
}

.node.active{

  .node-path {
    color: #3a78f2;
  }
    
  .node-path_icon--file,
  .node-path_icon--dir {
    fill: #3a78f2;
  }
}

.node.active::before {
  border-color: #3a78f2;
}

.node.sibling-active::before{
  border-left-color: #3a78f2;
}
.node.sibling-active::after {
  border-color: #3a78f2;
}
